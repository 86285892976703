//导入axios
import axios from 'axios'

export const api = axios.create({
    timeout: 100000, //请求超时设置，单位ms
    //baseURL: 'http://www.1942it.com:8089/gw' //之前测试
    //baseURL: 'http://47.114.166.189:8089/gw' //之前正式
    //baseURL: 'http://gatewayrestest.380v.com/gw' //测试
    baseURL: 'https://gateway.rest.pro.380v.com'  //正式
    // baseURL: 'https://gateway.company.rest.pro.380v.com'  //正式（企业实例）


})

export const apiCopy = axios.create({
    timeout: 100000, //请求超时设置，单位ms
    //baseURL: 'http://b.rest.test.gateway.380v.com' //测试
    baseURL: 'https://gateway.rest.b.pro.380v.com'  //正式
    // baseURL: 'https://gateway.company.rest.b.pro.380v.com'  //正式（企业实例）

})